import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Home } from './pages/home/index';
import { Music } from './pages/music/index';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/music" exact element={<Music />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
