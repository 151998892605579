// import React, { useState, useEffect } from "react";
import construction from "../../images/underconstruction.png"
import youtubelogo from "../../images/youtube.png"
import twitterlogo from "../../images/twitter.png"
import musiclogo from "../../images/music.png"
// import { useNavigate } from "react-router-dom";
import styles from './home.module.css';

export const Home = () => {
    // const navigate = useNavigate();

    return (
        <div className={styles["home-page"]}>
            <div className={styles["content"]}>
                <img src={construction} alt=""></img>
                <br></br><br></br>
                <iframe title="Fingerdash" width="530" height="300" src="https://www.youtube.com/embed/xPIZZIIKCJo"></iframe>

                <br></br><br></br>
                <h1 className={styles["fade-text-1"]}>My Links</h1>
                <table>
                    <tr className={styles["logos"]}>
                    <td><a href="https://youtube.com/@sdslayer100" title="My YouTube Channel!"><img alt="" className={styles["logos"]} src={youtubelogo}></img></a></td>
                    <td><a href="https://twitter.com/sdslayer100" title="My Twitter!"><img alt="" className={styles["logos"]} src={twitterlogo}></img></a></td>
                    <td><a href="../music" title="My Music!"><img alt="" className={styles["logos"]} src={musiclogo}></img></a></td>
                    </tr>
                </table>
            </div>

            <a href="https://oldsdslayer.netlify.app/"><p className={styles["oldsite-text"]}>Old, Bad Website</p></a>
        </div>
    );
};